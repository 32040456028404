import Vue from 'vue'
import Vuex from 'vuex'
import {login,sendSmsCode,expandList,deleOSSFile,getOSSPolicy} from '@/api/index.js'
import {setUserInfo,getUserInfo} from '@/utils/auth'
import {formatDate} from '@/utils/formatDate'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    userInfo:{}
  },
  getters: {
    userInfo: ({userInfo}) => {
        let info = JSON.stringify(userInfo) === '{}' ? getUserInfo() : userInfo;
        info.createTime = formatDate('YYYY-MM-DD',new Date(info.createTime));
        return info
    },
  },
  mutations: {
    setUserInfo(state,payload){
      setUserInfo(payload);
      state.userInfo = payload;
    }
  },
  actions: {
    login({commit},payload){
      return login(payload).then(res=>{
        if(res.status === 200){
          commit('setUserInfo',res.data);
        }
        return res;
      })
    },
    //发送短信
    sendSmsCode({commit},payload){
      return sendSmsCode(payload).then(res=>{
        return res;
      })
    },
    expandList({commit},payload){
      return expandList(payload).then(res=>{
        return res;
      })
    },
    getOSSPolicy({commit},payload){
      return getOSSPolicy(payload).then(res=>res)
    },
    deleOSSFile({commit},payload){
      return deleOSSFile(payload).then(res=>res)
    },
  },
})
