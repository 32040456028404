import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import '@/plugins/ydui.js';
import '@/font/iconfont.css';
import '@/css/common.css';


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
