import Cookies from 'js-cookie'

const Token = 'token';
const ContractNo = 'contractNo';//合同号

export function getToken () {
  let token = Cookies.get(Token)
  return token
}
export function setToken (token) {
  Cookies.set(Token, token)
}
export function removeToken () {
  return Cookies.remove(Token)
}

export async function setUserInfo (info) {
  await Cookies.set('userInfo', info)
}

export function getUserInfo () {
  let info = Cookies.get('userInfo')
  if (info) {
    return JSON.parse(info)
  }
  return info || {}
}
