import flyio from 'flyio';
// import store from '@/store'
import router from '@/router'
import {setToken,getToken} from '@/utils/auth'
import { Confirm, Alert, Toast, Notify, Loading } from 'vue-ydui/dist/lib.rem/dialog';
// flyio.config = {
//   ...flyio.config,
//   timeout: 30000,
//   withCredentials: true,
//   baseURL: process.env.VUE_APP_BASE_API,
  // headers: {
  //   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  // }
// }

flyio.interceptors.request.use(config => {
  config.timeout = 30000;
  config.withCredentials = true;
  config.baseURL = process.env.VUE_APP_BASE_API;
  config.parseJson = true;
  // config.headers = {
  //   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  // }
  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
  }
  
  const token = getToken();
  if(token){
    config.headers['js-token'] = token;
  }
  console.log(config)
  return config
}, err => err)

flyio.interceptors.response.use((res) => {
  if(res.headers['js-token']) {
    setToken(res.headers['js-token'])
  }
  if(res.data.status === 4001){
    setToken('');
    router.replace({ path: '/login'})
    return
  }else{
    return res.data
  }
  
}, error =>{
  console.log(error)
  Loading.close();
  Alert({mes: error.message});
})

export default flyio;
