export function formatDate(format = 'YYYYMMDDHHmmss', date = new Date()){
  var o = {
      "Y+": date.getFullYear(),
      "M+": date.getMonth() + 1, //月份
      "D+": date.getDate(), //日
      "H+": date.getHours(), //小时
      "m+": date.getMinutes(), //分
      "s+": date.getSeconds(), //秒
      "q+": Math.floor((date.getMonth() + 3) / 3), //季度
      "S": date.getMilliseconds() //毫秒
  };
  // if (/(y+)/.test(format)) {
  //   format = format.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  //   console.log(format)
  // }
  for (var k in o){
    // ret = new RegExp("(" + k + ")").exec(format);
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(RegExp.$1, (RegExp.$1.length == 1 || RegExp.$1.length == 4) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)) );
    }
  }
  return format;
}
Date.prototype.format = function (format = 'YYYYMMDDHHmmss'){
  var o = {
      "Y+": this.getFullYear(),
      "M+": this.getMonth() + 1, //月份
      "D+": this.getDate(), //日
      "H+": this.getHours(), //小时
      "m+": this.getMinutes(), //分
      "s+": this.getSeconds(), //秒
      "q+": Math.floor((this.getMonth() + 3) / 3), //季度
      "S": this.getMilliseconds() //毫秒
  };
  for (var k in o){
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(RegExp.$1, (RegExp.$1.length == 1 || RegExp.$1.length == 4) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)) );
    }
  }
  return format;
};

//时间差
function getDaysBetween(date1,date2){
  var  startDate = Date.parse(date1);
  var  endDate = Date.parse(date2);
  var days = (endDate - startDate)/(1*24*60*60*1000);
  return  days;
}
//获取月份天数
function getMonthLength(y = new Date().getFullYear(), m = new Date().getMonth()+1, d=1){
  var myDate= new Date();
  myDate.setFullYear(y,m-1,d);
  //设置某个月份的最后一天
  myDate.setMonth(m,0);
  var dates = myDate.getDate();
  return dates;
}
