import Vue from 'vue'
import VueRouter from 'vue-router'
import {getToken} from '@/utils/auth'

// import Home from '../views/Home.vue' popularizeCode

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue') //ContractConfirm
  },{
    path: '/',
    name: 'popularizeCode',
    component: () => import('../views/PopularizeCode.vue') //ContractConfirm
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // console.log(getToken())
  if (to.path !== '/login' && !getToken()) {
    next('/login?redirectPath=' + to.fullPath)
  } else {
    next()
  }
  // next()
})

export default router
