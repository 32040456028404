import Vue from 'vue';
// import ydui from 'vue-ydui';
import 'vue-ydui/dist/ydui.base.css';

import {ActionSheet} from 'vue-ydui/dist/lib.rem/actionsheet';
Vue.component(ActionSheet.name, ActionSheet);

//布局
import {Layout} from 'vue-ydui/dist/lib.rem/layout';
Vue.component(Layout.name, Layout);
//顶部导航
import {NavBar, NavBarBackIcon, NavBarNextIcon} from 'vue-ydui/dist/lib.rem/navbar';
Vue.component(NavBar.name, NavBar);
Vue.component(NavBarBackIcon.name, NavBarBackIcon);
Vue.component(NavBarNextIcon.name, NavBarNextIcon);
// Vue.use(NavBar)
//底部导航
import {TabBar, TabBarItem} from 'vue-ydui/dist/lib.rem/tabbar';
Vue.component(TabBar.name, TabBar);
Vue.component(TabBarItem.name, TabBarItem);
//按钮
import {Button, ButtonGroup} from 'vue-ydui/dist/lib.rem/button';
Vue.component(Button.name, Button);
Vue.component(ButtonGroup.name, ButtonGroup);
//提示框
import { Confirm, Alert, Toast, Notify, Loading } from 'vue-ydui/dist/lib.rem/dialog';
Vue.prototype.$dialog = {
    confirm: Confirm,
    alert: Alert,
    toast: Toast,
    notify: Notify,
    loading: Loading,
};
//多选框
import {CheckBox, CheckBoxGroup} from 'vue-ydui/dist/lib.rem/checkbox';
Vue.component(CheckBox.name, CheckBox);
Vue.component(CheckBoxGroup.name, CheckBoxGroup);
//单选
import {Radio, RadioGroup} from 'vue-ydui/dist/lib.rem/radio';
Vue.component(Radio.name, Radio);
Vue.component(RadioGroup.name, RadioGroup);

//表单
import {CellGroup, CellItem} from 'vue-ydui/dist/lib.rem/cell';
Vue.component(CellGroup.name, CellGroup);
Vue.component(CellItem.name, CellItem);

import {Input} from 'vue-ydui/dist/lib.rem/input';
Vue.component(Input.name, Input);

//角标
import {Badge} from 'vue-ydui/dist/lib.rem/badge';
Vue.component(Badge.name, Badge);

//选项卡
import {Tab, TabPanel} from 'vue-ydui/dist/lib.rem/tab';
Vue.component(Tab.name, Tab);
Vue.component(TabPanel.name, TabPanel);

//时间组件
import {DateTime} from 'vue-ydui/dist/lib.rem/datetime';
Vue.component(DateTime.name, DateTime);

//弹窗
import {Popup} from 'vue-ydui/dist/lib.rem/popup';
Vue.component(Popup.name, Popup);

//下拉刷新
import {PullRefresh} from 'vue-ydui/dist/lib.rem/pullrefresh';
Vue.component(PullRefresh.name, PullRefresh);
//滚动加载
import {InfiniteScroll} from 'vue-ydui/dist/lib.rem/infinitescroll';
Vue.component(InfiniteScroll.name, InfiniteScroll);
