/**
 * @desc 通用激活接口
 * @author: lijun
 * @version : v1.0
 */
import request from '@/utils/request'
import qs from 'qs';

const headers = {
    'Content-Type': 'application/json;charset=UTF-8'
}
/**
 * @desc 登录
 * @param  phone code 
 */
export const login = data=>{
    return request.post('/expand/user/login',qs.stringify(data))
}

/**
 * @desc 发送短信
 * @param  phone smsKey='loginCode'
 */
export const sendSmsCode = data=>{
    return request.post('/sms/sendSmsCode',qs.stringify(data))
}

/**
 * @desc 推广记录列表
 * @param  currentPage endDay pageSize startDay state type userId
 */ 
export const expandList = params=>{
    return request.get('/expand/user/expandRecordList',params)
}

/**
 * @desc 获取OSS签名
 * @param  
 */ 
export const getOSSPolicy = params=>{
    return request.get('/expand/user/aliyunOSSPolicy',params)
}
/**
 * @desc 获取OSS签名
 * @param  
 */ 
export const deleOSSFile = data=>{
    return request.post('/expand/user/deleteOssFile',qs.stringify(data))
}